.header {
  -webkit-transition             : all 0.8s ease-in-out;
  -moz-transition                : all 0.8s ease-in-out;
  -o-transition                  : all 0.8s ease-in-out;
  transition                     : all 0.8s ease-in-out;
  position                       : relative;
  display                        :absolute;
  top                            :0;
  left                           :0;
  border-bottom                  : 5px solid var(--color-secondary);
  height                         : var(--header-height);
}

.header_logo {
  justify-self                   : flex-end;
  height                         : calc(var(--header-height) * 2 / 3);
  margin-top                     : calc(var(--header-height) * 1 / 6);
  cursor                         : pointer;
}
.header_logo_v2 {
  justify-self                   : flex-start;
  height                         : calc(var(--header-height) * 2 / 3);
  margin-top                     : calc(var(--header-height) * 1 / 6);
  cursor                         : pointer;
}
.header_logo_img {
  height                         : 100%;
}
.header_title {
  color                          : var(--color-primary-txt);
  font-size                      : 3rem;
  display                        : flex;
  align-items                    : center;
  height                         : var(--header-height);
  justify-content                : flex-start;
  text-align                     : left;
}
.drawer-container {
  height                         : 100%;
  position                       : relative;
  color                          : var(--color-primary-txt);
  background-color               : color-mix(in srgb, rgba(var(--color-primary-raw), 0.5) 20% , rgba(0, 0, 0, 0.5) 80%);
}
@media only screen and (max-width: 960px) {
  .header_title {
    font-size                    : 3vh !important;
  }
  
  .drawer-container {
    width                        : 100vw !important;
  }
}
@media only screen and (min-width: 960px) {
  .drawer-container {
    width                        : 250px;
  }

}

.toolbar_icons {
  /*margin-left                  : auto;*/
 /* margin-left                  :5rem;*/
  margin-top                     :1rem;
  background-color               : rgba(0, 0, 0, 0.1);
  border-radius                  : 20px;
  padding                        : 4px;
}

.toolbar_icons .MuiIconButton-root {
  color                          : white;
}
.toolbar_icons                   :hover .transparent-header-icon{
  opacity                        :1 !important;
}


