


.dashboard-button-wrapper {
    transition: all 0.3s ease;
    padding: 0.5rem;
    background-color: var(--color-secondary-light-50) !important;
}

.dashboard-button{
    transition: all 0.3s ease;
    background-color: var(--color-secondary) !important;
    color: var(--color-secondary-txt) !important;
}

.dashboard-button-wrapper:hover {
    background-color: var(--color-primary-light-50) !important;
}
.dashboard-button:hover{
    background-color: var(--color-primary) !important;
    color: var(--color-primary-txt) !important;
}