.layer-selector {
    position: relative;
    display: inline-block;
  }
  
  .layer-selector-trigger {
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .layer-selector-trigger:hover {
    /* background-color: color-mix(in srgb, rgba(var(--color-primary-raw), 0.8) 20% , rgba(255, 255, 255, 0.8) 80%); */
    background-color: white; 
    transform: scale(1.05);
    color:black;
  }
  
  .layer-selector-dropdown {
   /* z-index: 201;*/
    position: absolute;
    background-color: color-mix(in srgb, rgba(var(--color-primary-raw), 0.7) 20% , rgba(0, 0, 0, 0.7) 80%);
    color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 450px;
    /*backdrop-filter: blur(10px);*/
    border-radius: 1rem;
   /* height: 700px;*/
   display:flex;
   flex-direction: column;
   height:75vh;
   overflow-wrap:normal;
   overflow-y: scroll !important;
/*    max-height: 80vh;*/
   /* max-height: 75vh;*/
     /* Mantén el scroll vertical si es necesario */
   /* overflow-x: visible; /* Asegura que no recorte el contenido horizontalmente */
}
  @media only screen and (max-width: 960px) {
    .layer-selector-dropdown {
      width:calc(100vw - 5vw) !important;
      /*right:0;
      top:0;*/
      right:0 !important;
      height:90vh;
      max-height: 80vh;
      overflow-y: scroll;
    }
    .layer-selector{
      position:static !important;
    }

  }
  .layer-selector-dropdown-text-container{
    padding: 0.3rem;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 0.8rem;
    width:100%;
  }
  .layer-thumbnail{
    width:150px ;
    height: 150px ;
    max-height: 150px;
    object-fit: cover;
    
  }
  
.layer-option {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: all 0.2s ease;
 /* overflow: visible;*/ /* Permitir que el contenido sobresalga al escalar */
}
@media screen and (min-height:900px)  and (min-width: 960px){
  .layer-option-hover:hover {
    scale: 1.25;
  }
  .layer-selector-dropdown {
   /* overflow-y: unset !important;*/
  }
}
.layer-selector-dropdown:hover .layer-option:not(:hover) {
  background-color: color-mix(in srgb, rgba(var(--color-primary-raw), 0.7) 20% , rgba(0, 0, 0, 0.7) 80%) !important;
}
  .layer-option:hover {
    background-color: white; /* Estilo de hover */
  color: black;
  border: 2px solid var(--color-primary);
 /* backdrop-filter: blur(10px);*/
  padding:5px;
  z-index: 101; /* Asegurar que se superponga a los elementos adyacentes */
  transform-origin: center; /* Controlar desde dónde se expande el escalado */
}
.layer-option:hover h4{
  font-size: 18px;

}
.layer-option:hover .layer-thumbnail {
  width:155px !important;
  height: 155px !important;
  max-height: 155px !important;
  margin-right: 10px !important;
  border-radius: 1rem;
}
  .layer-option:hover p{
    color:#232323;
    font-size: 16px;
    padding-top: 0.2rem;

  }
  .layer-option:last-child {
    border-bottom: none;
  }
  
  .layer-option img {
  
    margin-right: 10px;
  }
  
  .layer-option h4 {
    margin: 0;
    font-size: 18px;
  }
  
  .layer-option p {
    margin: 0;
    font-size: 14px;
    color:#cdcdcd;
    text-align: left;
    padding-top: 1rem;
  }
  
  /* Direcciones del dropdown */
  .layer-selector-dropdown-up {
    bottom: 100%;
    right: 0;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 960px) {
    .layer-selector-dropdown-up {
      
     /* right: -8em !important;*/
      
    }
  }
  
  .layer-selector-dropdown-down {
    top: 100%;
    left: 0;
    margin-top: 10px;
  }
  
  .layer-selector-dropdown-left {
    right: 100%;
    top: 0;
    margin-right: 10px;
  }
  
  .layer-selector-dropdown-right {
    left: 100%;
    top: 0;
    margin-left: 10px;
  }
  

  