body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  box-sizing: border-box !important;
  --max-article-width: 1200px;
  --header-height: min(max(10vh, 80px), 100px);
  --footer-height: 5rem;

  --color-primary-raw      : 0, 0, 255;
  --color-secondary-raw    : 0, 255, 0;

  --color-error-raw        : 207, 12, 12;
  --color-error-txt        : white;
  --color-primary-txt   : white;
  --color-secondary-txt : black;
  
  --color-icons        : #00ff00;


  /* Grises oscuros para fondos */
  --color-txt-1: #1c1c1e;  /* Fondo oscuro 1 */
  --color-txt-2: #2c2c2e;  /* Fondo oscuro 2 */
  --color-txt-3: #3a3a3c;  /* Fondo oscuro 3 */

  /* Grises claros complementarios para texto */
  --color-bg-1: #f1f1f1;  /* Texto claro sobre fondo oscuro 1 */
  --color-bg-2: #e5e5e5;  /* Texto claro sobre fondo oscuro 2 */
  --color-bg-3: #d9d9d9;  /* Texto claro sobre fondo oscuro 3 */

  --color-primary       : rgb(var(--color-primary-raw));
  --color-secondary     : rgb(var(--color-secondary-raw));
  --color-error         : rgb(var(-color-error-raw));

  /*----------------------- primary ------------------------------------------*/
  /*------------------------------------------------------------------------
  --------------------------------------------------------------------------
  --------------------------------------------------------------------------*/
  --color-primary-transparent-10 : rgba(var(--color-primary-raw), 0.1); /* 10% de opacidad */
  --color-primary-transparent-20 : rgba(var(--color-primary-raw), 0.2); /* 20% de opacidad */
  --color-primary-transparent-30 : rgba(var(--color-primary-raw), 0.3); /* 30% de opacidad */
  --color-primary-transparent-40 : rgba(var(--color-primary-raw), 0.4); /* 30% de opacidad */
  --color-primary-transparent-50 : rgba(var(--color-primary-raw), 0.5); /* 30% de opacidad */
  --color-primary-transparent-60 : rgba(var(--color-primary-raw), 0.6); /* 30% de opacidad */
  --color-primary-transparent-70 : rgba(var(--color-primary-raw), 0.7); /* 30% de opacidad */
  --color-primary-transparent-80 : rgba(var(--color-primary-raw), 0.8); /* 30% de opacidad */
  --color-primary-transparent-90 : rgba(var(--color-primary-raw), 0.9); /* 30% de opacidad */


  /* Mezcla de color primario con blanco (para aclarar) */
  --color-primary-light-10  : color-mix(in srgb, var(--color-primary) 90%, white 10%);
  --color-primary-light-20  : color-mix(in srgb, var(--color-primary) 80%, white 20%);
  --color-primary-light-30  : color-mix(in srgb, var(--color-primary) 70%, white 30%);
  --color-primary-light-40  : color-mix(in srgb, var(--color-primary) 60%, white 40%);
  --color-primary-light-50  : color-mix(in srgb, var(--color-primary) 50%, white 50%);
  --color-primary-light-60  : color-mix(in srgb, var(--color-primary) 40%, white 60%);
  --color-primary-light-70  : color-mix(in srgb, var(--color-primary) 30%, white 70%);
  --color-primary-light-80  : color-mix(in srgb, var(--color-primary) 20%, white 80%);
  --color-primary-light-90  : color-mix(in srgb, var(--color-primary) 10%, white 90%);

  /* Mezcla de color primario con negro (para oscurecer) */
  /*--color-primary-dark-10   : color-mix(in srgb, var(--color-primary) 90%, black 10%);
  --color-primary-dark-20   : color-mix(in srgb, var(--color-primary) 80%, black 20%);
  --color-primary-dark-30   : color-mix(in srgb, var(--color-primary) 70%, black 30%);
  --color-primary-dark-40   : color-mix(in srgb, var(--color-primary) 60%, black 40%);
  --color-primary-dark-50   : color-mix(in srgb, var(--color-primary) 50%, black 50%); */



  /*----------------------- secondary ------------------------------------------*/
  /*------------------------------------------------------------------------
  --------------------------------------------------------------------------
  --------------------------------------------------------------------------*/
  --color-secondary-transparent-10 : rgba(var(--color-secondary-raw), 0.1); /* 10% de opacidad */
  --color-secondary-transparent-20 : rgba(var(--color-secondary-raw), 0.2); /* 20% de opacidad */
  --color-secondary-transparent-30 : rgba(var(--color-secondary-raw), 0.3); /* 30% de opacidad */
  --color-secondary-transparent-40 : rgba(var(--color-secondary-raw), 0.4); /* 30% de opacidad */
  --color-secondary-transparent-50 : rgba(var(--color-secondary-raw), 0.5); /* 30% de opacidad */
  --color-secondary-transparent-60 : rgba(var(--color-secondary-raw), 0.6); /* 30% de opacidad */
  --color-secondary-transparent-70 : rgba(var(--color-secondary-raw), 0.7); /* 30% de opacidad */
  --color-secondary-transparent-80 : rgba(var(--color-secondary-raw), 0.8); /* 30% de opacidad */
  --color-secondary-transparent-90 : rgba(var(--color-secondary-raw), 0.9); /* 30% de opacidad */


  /* Mezcla de color secundario con blanco (para aclarar) */
  --color-secondary-light-10  : color-mix(in srgb, var(--color-secondary) 90%, white 10%);
  --color-secondary-light-20  : color-mix(in srgb, var(--color-secondary) 80%, white 20%);
  --color-secondary-light-30  : color-mix(in srgb, var(--color-secondary) 70%, white 30%);
  --color-secondary-light-40  : color-mix(in srgb, var(--color-secondary) 60%, white 40%);
  --color-secondary-light-50  : color-mix(in srgb, var(--color-secondary) 50%, white 50%);
  --color-secondary-light-60  : color-mix(in srgb, var(--color-secondary) 40%, white 60%);
  --color-secondary-light-70  : color-mix(in srgb, var(--color-secondary) 30%, white 70%);
  --color-secondary-light-80  : color-mix(in srgb, var(--color-secondary) 20%, white 80%);
  --color-secondary-light-90  : color-mix(in srgb, var(--color-secondary) 10%, white 90%);
  /* Mezcla de color secundario con negro (para oscurecer) */
  /* --color-secondary-dark-10   : color-mix(in srgb, var(--color-secondary) 90%, black 10%);
  --color-secondary-dark-20   : color-mix(in srgb, var(--color-secondary) 80%, black 20%);
  --color-secondary-dark-30   : color-mix(in srgb, var(--color-secondary) 70%, black 30%);
  --color-secondary-dark-40   : color-mix(in srgb, var(--color-secondary) 60%, black 40%);
  --color-secondary-dark-50   : color-mix(in srgb, var(--color-secondary) 50%, black 50%); */

  


  /*----------------------- ERROR ------------------------------------------*/
  /*------------------------------------------------------------------------
  --------------------------------------------------------------------------
  --------------------------------------------------------------------------*/
  
  --color-error-transparent-10 : rgba(var(--color-error-raw), 0.1); /* 10% de opacidad */
  --color-error-transparent-20 : rgba(var(--color-error-raw), 0.2); /* 20% de opacidad */
  --color-error-transparent-30 : rgba(var(--color-error-raw), 0.3); /* 30% de opacidad */
  --color-error-transparent-40 : rgba(var(--color-error-raw), 0.4); /* 30% de opacidad */
  --color-error-transparent-50 : rgba(var(--color-error-raw), 0.5); /* 30% de opacidad */
  --color-error-transparent-60 : rgba(var(--color-error-raw), 0.6); /* 30% de opacidad */
  --color-error-transparent-70 : rgba(var(--color-error-raw), 0.7); /* 30% de opacidad */
  --color-error-transparent-80 : rgba(var(--color-error-raw), 0.8); /* 30% de opacidad */
  --color-error-transparent-90 : rgba(var(--color-error-raw), 0.9); /* 30% de opacidad */
  /* Mezcla de color de error con blanco (para aclarar) */
  --color-error-light-10      : color-mix(in srgb, var(--color-error) 90%, white 10%);
  --color-error-light-20      : color-mix(in srgb, var(--color-error) 80%, white 20%);
  --color-error-light-30      : color-mix(in srgb, var(--color-error) 70%, white 30%);
  --color-error-light-40      : color-mix(in srgb, var(--color-error) 60%, white 40%);
  --color-error-light-50      : color-mix(in srgb, var(--color-error) 50%, white 50%);

  /* Mezcla de color de error con negro (para oscurecer) */
  /* --color-error-dark-10       : color-mix(in srgb, var(--color-error) 90%, black 10%);
  --color-error-dark-20       : color-mix(in srgb, var(--color-error) 80%, black 20%);
  --color-error-dark-30       : color-mix(in srgb, var(--color-error) 70%, black 30%);
  --color-error-dark-40       : color-mix(in srgb, var(--color-error) 60%, black 40%);
  --color-error-dark-50       : color-mix(in srgb, var(--color-error) 50%, black 50%);*/
} 


/* Tema oscuro */
body.dark-theme {



  --color-primary-raw      : 0 0 255;
  --color-secondary-raw    : 0 255 0;

  --color-error-raw        : 207, 12, 12;
  /*--color-error        : #cf0c0c;*/
  --color-error-txt        : white;
  --color-primary-txt   : white;
  --color-secondary-txt : black;
  
  --color-icons        : #00ff00;

  /* Grises oscuros para fondos */
  --color-bg-1: #1c1c1e; 
  --color-bg-2: #2c2c2e; 
  --color-bg-3: #3a3a3c;  

  /* Grises claros complementarios para texto */
  --color-txt-1: #f1f1f1;  
  --color-txt-2: #e5e5e5;  
  --color-txt-3: #d9d9d9;  

  --color-primary       : rgb(var(--color-primary-raw));
  --color-secondary     : rgb(var(--color-secondary-raw));
  --color-error         : rgb(var(-color-error-raw));

  /*----------------------- primary ------------------------------------------*/
  /*------------------------------------------------------------------------
  --------------------------------------------------------------------------
  --------------------------------------------------------------------------*/

  
  /* Mezcla de color primario con negro (para oscurecer) */
  --color-primary-light-10   : color-mix(in srgb, var(--color-primary) 90%, black 10%);
  --color-primary-light-20   : color-mix(in srgb, var(--color-primary) 80%, black 20%);
  --color-primary-light-30   : color-mix(in srgb, var(--color-primary) 70%, black 30%);
  --color-primary-light-40   : color-mix(in srgb, var(--color-primary) 60%, black 40%);
  --color-primary-light-50   : color-mix(in srgb, var(--color-primary) 50%, black 50%);



  /*----------------------- secondary ------------------------------------------*/
  /*------------------------------------------------------------------------
  --------------------------------------------------------------------------
  --------------------------------------------------------------------------*/

  /* Mezcla de color secundario con negro (para oscurecer) */
  --color-secondary-light-10   : color-mix(in srgb, var(--color-secondary) 90%, black 10%);
  --color-secondary-light-20   : color-mix(in srgb, var(--color-secondary) 80%, black 20%);
  --color-secondary-light-30   : color-mix(in srgb, var(--color-secondary) 70%, black 30%);
  --color-secondary-light-40   : color-mix(in srgb, var(--color-secondary) 60%, black 40%);
  --color-secondary-light-50   : color-mix(in srgb, var(--color-secondary) 50%, black 50%); 

  


  /*----------------------- ERROR ------------------------------------------*/
  /*------------------------------------------------------------------------
  --------------------------------------------------------------------------
  --------------------------------------------------------------------------*/
  

  /* Mezcla de color de error con negro (para oscurecer) */
  --color-error-light-10       : color-mix(in srgb, var(--color-error) 90%, black 10%);
  --color-error-light-20       : color-mix(in srgb, var(--color-error) 80%, black 20%);
  --color-error-light-30       : color-mix(in srgb, var(--color-error) 70%, black 30%);
  --color-error-light-40       : color-mix(in srgb, var(--color-error) 60%, black 40%);
  --color-error-light-50       : color-mix(in srgb, var(--color-error) 50%, black 50%);
} 