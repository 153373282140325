.custom-zoom-control {
    top                : 10px;
    left               : 10px;
    position           : absolute;
  }
  
  .move-control {
    top                : 100px;
    left               : 10px;
    position           : absolute;
  }
  
  .ol-zoom {
    background-color   : rgba(255, 255, 255, 0.8);
    border-radius      : 4px;
    padding            : 5px;
  }
  
  .ol-zoom button {
    background-color   : #fff;
    border             : none;
    border-radius      : 2px;
    cursor             : pointer;
    font-size          : 18px;
    height             : 30px;
    width              : 30px;
  }
  
  .move-btn {
    background-color   : #007bff;
    color              : white;
    border             : none;
    border-radius      : 4px;
    padding            : 6px 12px;
    cursor             : pointer;
  }
  
  .move-btn            :hover {
    background-color   : #0056b3;
  }
  
  .map-control-container {
    background-color   : rgba(0, 0, 0, 0.5); /* Fondo semi-transparente oscuro */
    border-radius      : 10px; /* Bordes redondeados */
    padding            : 10px; /* Espaciado interno */
    display            : flex; /* Flex para organizar los controles */
    flex-direction     : column; /* Colocar los controles en una columna */
    align-items        : center; /* Centrar los controles horizontalmente */
    box-shadow         : 0px 4px 6px rgba(0, 0, 0, 0.1); /* Sombras para darle relieve */
    gap                : 10px; /* Espacio entre los controles */
    z-index            : 1000; /* Asegurarse de que el contenedor esté por encima del mapa */
  }
  

  .bg-dark{
    background-color   : color-mix(in srgb, rgba(var(--color-primary-raw), 0.2) 20% , rgba(0, 0, 0, 0.2) 80%);
    border-radius      : 0.7rem;
    padding            :0.7rem;
   transition          : all 0.4s ease;
   z-index:50;
  }
  .bg-dark .bg-dark-svg{
    color              :white;
  }
  .bg-dark             :hover{
    background-color   : color-mix(in srgb, rgba(var(--color-primary-raw), 0.8) 20% , rgba(255, 255, 255, 0.8) 80%);
  }

  .bg-dark             :hover .bg-dark-svg{
    color              :rgba(0, 0, 0, 1) !important;
    background-color   : color-mix(in srgb, rgba(var(--color-primary-raw), 0.9) 20% , rgba(255, 255, 255, 0.9) 80%);
  }
  .bg-dark .bg-dark-svg:hover{
    background-color   : color-mix(in srgb, rgba(var(--color-primary-raw), 1) 40% , rgba(255, 255, 255, 1) 60%);
    color:black;

  }
.bg-hover-icon:hover{
  background-color   : color-mix(in srgb, rgba(var(--color-primary-raw), 0.9) 20% , rgba(255, 255, 255, 0.9) 80%)!important;
  color              :rgba(0, 0, 0, 1) !important;
}
  .control-panel{
    display            :flex;
    flex-direction     : row;
    align-items        :end;
    gap                :10px;
    z-index:50;
  }
  .control-panel-container{

  }