.footer {
  padding: 1.5rem 0%;
  width: 100%;
  height: var(--footer-height);
  background-color: var(--color-primary);
  display: grid;
  flex-direction: column;
  min-width: 4rem;
  color: var(--color-primary-txt);
}
.footer_row {
  width: 100%;
  max-width: var(--max-article-width);
  display: flex;
  justify-self: center;
}
.footer_column {
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer_label {
  color: var(--color-primary-txt);
  text-align: left;
  font-size: 0.8rem;
}
.footer_title {
  font-size: 1.2rem;
}
a {
  cursor: pointer;
}
a > * {
  cursor: pointer;
}
