.autocomplete-container {
  /*the container must be positioned relative:*/
  position: relative;
}
.geocoder-container {
/*  margin-top: 15px;*/
  /*margin-left: 3rem;*/
  width:100%;
  max-width: min(30vw, 100%) ;

}
.geoapify-autocomplete-item {
  color: black;
  backdrop-filter: blur(10px);
  background-color: color-mix(
    in srgb,
    rgba(var(--color-primary-raw), 0.6) 10%,
    rgba(255, 255, 255, 0.6) 90%
  ) !important;
  border-radius: 1rem !important;
  border-bottom:1px solid var(--color-primary-txt)
}
.geoapify-autocomplete-items {
  background-color: transparent !important;
  border:none !important;

}
.geoapify-autocomplete-item  .icon{
  color: var(--color-secondary-txt) !important;
  background-color: var(--color-secondary) !important;
  margin-right:1rem !important;
  padding:0.2rem;
  border-radius: 0.5rem;

}
.geoapify-autocomplete-item .adress .main-part {
  color: black !important;
}
.geoapify-autocomplete-item .adress .secondary-part {
  color: black !important;
}
.secondary-part{
  color: black !important;
}

.geoapify-autocomplete-item:hover{
  color: var(--color-primary-txt) !important;
  background-color: var(--color-primary) !important;
  font-weight: bold;
}
.geoapify-autocomplete-item:hover .adress .icon {
  color:  var(--color-primary-txt);
}
.geoapify-autocomplete-item:hover .adress .main-part {
  color:  var(--color-primary-txt) !important;
}
.geoapify-autocomplete-item:hover .adress .secondary-part {
  color:  var(--color-primary-txt) !important;
}
.geoapify-autocomplete-item:hover .secondary-part{
  color:  var(--color-primary-txt) !important;
}
.geoapify-autocomplete-item:hover  .icon{
  color: var(--color-secondary-txt) !important;
  background-color: var(--color-secondary) !important;
  margin-right:1rem !important;
  padding:0.2rem;
  border-radius: 0.5rem;

}
.geoapify-close-button{
  color: var(--color-primary-txt) !important;
}
/* .autocomplete-container input {
  width: calc(100% + 40px);
  outline: none;
  min-width: 250px;
  border-radius: 1rem;
  padding-top: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px;
  font-size: 16px;
  background-color: color-mix(
    in srgb,
    rgba(var(--color-primary-raw), 0.2) 20%,
    rgba(0, 0, 0, 0.2) 80%
  ) !important;
} */
/* .geoapify-autocomplete-input-box	{
    position:absolute;
    top:25px;
    left:.5em;
    min-width: 10rem;
    min-height: 1rem;
    width:17rem;
    background-color: white;
    border: solid 1px #999;
    border-radius: 4px;
    box-shadow: 1rem 0 1em 0 rgba(0, 0, 0, 0.2);
    background-color: transparent;
    border: transparent;
    color:transparent;
 } */

 ::placeholder  {
  color: white;
  opacity: 1; /* Firefox */
}
::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    white;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
 color:    #fff;
 opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
 color:    #fff;
 opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color:    #fff;
}
::-ms-input-placeholder { /* Microsoft Edge */
 color:    #fff;
}


.geoapify-autocomplete-input {
  /* position:absolute;
   top:0px !important;
   left:0px !important; */
  border-radius: 1rem;
  box-sizing: border-box;
  padding: 1rem !important;
 /* padding-top: 1.25rem !important;*/
  /*padding-bottom: 1.25rem !important;*/
  /*padding-right: 1rem !important;*/
  background-color: color-mix(
    in srgb,
    rgba(var(--color-primary-raw), 0.3) 20%,
    rgba(0, 0, 0, 0.3) 80%
  ) !important;
  width:max(30vw, 100%) !important;
    color: #fff !important;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  border: 0;
}
@media only screen and (max-width:600px) {
  .geoapify-autocomplete-input {
    padding-top: 0.25rem !important ;
    padding-bottom: 0.25rem !important ;
  }
  .geocoder-container{
    top:0 !important;
  }
}
@media only screen and (min-width:599px) {
  .geoapify-autocomplete-input {
    padding-top: 1.5rem !important ;
    padding-bottom: 1.5rem !important ;
  }
  .geocoder-container{
    margin-top:15px !important;
  }
}