.custom-print-button{
    position:absolute;
    top:-50%;
    left:-50%;
    z-index:-999;
}
.custom-print-button button{
    height:1.5rem;
    border-radius: 1rem;
    background-color   : color-mix(in srgb, rgba(var(--color-primary-raw), 0.2) 20% , rgba(0, 0, 0, 0.2) 80%);
    border-radius      : 0.7rem;
    padding            :0.7rem;
   transition          : all 0.4s ease;
   content: 'Imprimir mapa';
}
.custom-print-button button             :hover{
    background-color   : color-mix(in srgb, rgba(var(--color-primary-raw), 0.8) 20% , rgba(255, 255, 255, 0.8) 80%);
  }
  .ol-print-north{
    display:none;
  }
  .ol-margin{
    display:none;
  }
  .ol-print-compass{
    display:none !important;
  }